<template>
  <v-expansion-panels focusable class="roles">
    <v-expansion-panel class="ma-2">
      <v-expansion-panel-header color="secondary lighten-2" class="white--text">
        {{ permissionName }}
      </v-expansion-panel-header>
      <v-expansion-panel-content style="border: 1px solid #eee">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="childPermission in permissionChilds"
            :key="childPermission.name"
          >
            <v-checkbox
              v-model="childPermission.isGranted"
              color="green"
              :label="childPermission.displayName"
              @change="onPermissionChange(childPermission)"
              off-icon="mdi-lock"
              on-icon="mdi-marker-check"
              :class="getClass(childPermission.isGranted)"
              :ripple="true"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { get } from 'http';

export default {
  props: ['permission', 'childs', 'value'],
  computed: {
    getPermission() {
      return this.permission || {};
    },
    permissionName() {
      const { displayName } = this.getPermission;
      return displayName;
    },
    permissionChilds() {
      return this.childs || [];
    },
    postModifyPermissions: {
      get() {
        return this.modifyPermissions;
      },
      set(modifyPermissions) {
        this.$emit('input', modifyPermissions);
      },
    },
    modifyPermissions() {
      return this.value;
    },
  },
  methods: {
    onPermissionChange(permission) {
      const isModify = this.checkIsInModifyPermissions(permission);
      if (isModify) {
        // remove
        const modifyPermissions = [...this.modifyPermissions];
        const index = modifyPermissions.findIndex(
          (p) => p.name == permission.name,
        );
        modifyPermissions.splice(index, 1);
        this.postModifyPermissions = modifyPermissions;
        return;
      }

      // add
      this.postModifyPermissions = [
        ...this.modifyPermissions,
        { ...permission },
      ];
    },
    checkIsInModifyPermissions(permission) {
      const modifyPermission = this.modifyPermissions.find(
        (p) => p.name == permission.name,
      );
      if (!modifyPermission) return false;
      return true;
    },
    getClass(granted) {
      return granted && 'childpermission-granted';
    },
  },
};
</script>

<style lang="scss">
.roles .v-expansion-panel-header__icon i {
  color: #fff !important;
}
.childpermission-granted {
  label {
    color: green !important;
  }
}
</style>
